import { GridActionsCellItem } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import { useStore } from "../../../app/stores/store";
import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { GridColDef, GridValidRowModel } from "@mui/x-data-grid-premium";
import { StyledDataGridPremium } from "../../../app/component/StyledDataGridPremium";
import { Box } from "@mui/material";

function ObjectGrid() {
  const { objectGridStore, commonStore } = useStore();
  const { getObjectList, loadingGrid } = objectGridStore;
  const navigate = useNavigate();

  const gridColumns: GridColDef<GridValidRowModel>[] = [
    {
      field: "objectTypeName",
      headerName: "Object Name",
      width: 110,
      type: "string",
      headerAlign: "center",
      sortable: true,
      align: "center",
      renderCell: (params) => <Box sx={{ color: params.row.isDeleted ? "red" : "" }}>{params.row.objectTypeName}</Box>,
    },
    {
      field: "objectName",
      headerName: "Well Name",
      width: 110,
      type: "string",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => <Box sx={{ color: !params.row.isDeleted ? "red" : "" }}>{params.row.objectName}</Box>,
    },
    {
      field: "wellSite",
      headerName: "Well Site",
      width: 110,
      type: "string",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => <Box sx={{ color: params.row.isDeleted ? "red" : "" }}>{params.row.wellSite}</Box>,
    },
    {
      field: "isActive",
      headerName: "Status",
      type: "string",
      filterable: false,
      sortable: true,
      headerAlign: "center",
      align: "center",
      flex: 1,
      width: 110,
      renderCell: (params) =>
        params.row.isActive ? (
          <Box sx={{ color: params.row.isDeleted ? "red" : "" }}>Active</Box>
        ) : (
          <Box sx={{ color: params.row.isDeleted ? "red" : "" }}>Non Active</Box>
        ),
    },
    {
      field: "drillingSpudDate",
      headerName: "Drilling Spud Date",
      headerAlign: "center",
      align: "center",
      width: 170,
      type: "dateTime",
      filterable: false,
      sortable: true,
      renderCell: (params) => (
        <Box sx={{ color: params.row.isDeleted ? "red" : "" }}>{format(new Date(params.row.drillingSpudDate), "dd MMMM yyyy")}</Box>
      ),
    },
    {
      field: "remarks",
      headerName: "Remarks",
      width: 110,
      type: "string",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => <Box sx={{ color: params.row.isDeleted ? "red" : "" }}>{params.row.remarks}</Box>,
    },
    {
      field: "actions",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      type: "actions",
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="View"
            color="info"
            onClick={() => {
              navigate(`edit/${params.id}`);
            }}
          />,
        ];
      },
    },
  ];

  useEffect(() => {
    getObjectList();
  }, [getObjectList]);

  return (
    <StyledDataGridPremium
      loading={loadingGrid}
      sx={{ ".datagrid-isDeleted-true": { background: "#FCE5D5" } }}
      getRowClassName={(params: any) => (params.row.isDeleted ? "datagrid-isDeleted-true" : "")}
      autoHeight
      showCellVerticalBorder
      showColumnVerticalBorder
      checkboxSelection
      pagination
      disableColumnMenu
      pageSizeOptions={[10, 25, 50, 100]}
      onRowSelectionModelChange={(newSelectionModel: any) => {
        commonStore.setSelectionDataOnGrid(newSelectionModel);
      }}
      columns={gridColumns}
      rows={objectGridStore.dataGridResult.data}
    />
  );
}

export default observer(ObjectGrid);
