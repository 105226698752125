import React, { useEffect, useMemo, useState } from "react";
import RkdModalForm from "../../../app/component/modal/RkdModalForm";
import RkdForm from "../../../app/component/form/RkdForm";
import * as Yup from "yup";
import { TBrineORCDataV2 } from "../../../app/models/geochemistry";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useStore } from "../../../app/stores/store";
import { TOrcOptions } from "../../../app/models/orc";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

interface IBrineORCCreateModalProps {
  onClose: () => void;
  open: boolean;
}

function BrineORCCreateModal(props: IBrineORCCreateModalProps) {
  const { onClose, open } = props;
  const { orcGridStore, orcGroupGridStore, brineORCMonitoringStore } =
    useStore();
  const [orcOptions, setOrcOptions] = useState<TOrcOptions[] | []>([]);

  const validationSchema = Yup.object({
    orcGroupId: Yup.string().required(),
    orcId: Yup.string().required(),
    brineOrcDate: Yup.date().required(),
    flowRate: Yup.number().required(),
    pressureInlet: Yup.number().required(),
    pressureOutlet: Yup.number().required(),
    deltaPressure: Yup.number().required(),
    temperatureInlet: Yup.number().required(),
    temperatureOutlet: Yup.number().required(),
    deltaTemperature: Yup.number().required(),
    grossGeneration: Yup.number().required(),
    polymerization: Yup.number().required(),
    declineGeneration: Yup.number().required(),
    declineDenerationChart: Yup.number().required(),
    valueA: Yup.number().required(),
    valueB: Yup.number().required(),
    valueC: Yup.number().required(),
    valueD: Yup.number().required(),
    valueE: Yup.number().required(),
  });

  const initialValues: TBrineORCDataV2 = useMemo(() => {
    return {
      orcGroupId: brineORCMonitoringStore?.selectedBrineORCV2?.orcGroupId || "",
      orcId: brineORCMonitoringStore?.selectedBrineORCV2?.orcId || "",
      brineOrcDate:
        brineORCMonitoringStore?.selectedBrineORCV2?.brineOrcDate || new Date(),
      flowRate: brineORCMonitoringStore?.selectedBrineORCV2?.flowRate || 0,
      pressureInlet:
        brineORCMonitoringStore?.selectedBrineORCV2?.pressureInlet || 0,
      pressureOutlet:
        brineORCMonitoringStore?.selectedBrineORCV2?.pressureOutlet || 0,
      deltaPressure:
        brineORCMonitoringStore?.selectedBrineORCV2?.deltaPressure || 0,
      temperatureInlet:
        brineORCMonitoringStore?.selectedBrineORCV2?.temperatureInlet || 0,
      temperatureOutlet:
        brineORCMonitoringStore?.selectedBrineORCV2?.temperatureOutlet || 0,
      deltaTemperature:
        brineORCMonitoringStore?.selectedBrineORCV2?.deltaTemperature || 0,
      grossGeneration:
        brineORCMonitoringStore?.selectedBrineORCV2?.grossGeneration || 0,
      polymerization:
        brineORCMonitoringStore?.selectedBrineORCV2?.polymerization || 0,
      declineGeneration:
        brineORCMonitoringStore?.selectedBrineORCV2?.declineGeneration || 0,
      declineDenerationChart:
        brineORCMonitoringStore?.selectedBrineORCV2?.declineDenerationChart ||
        0,
      valueA: brineORCMonitoringStore?.selectedBrineORCV2?.valueA || 0,
      valueB: brineORCMonitoringStore?.selectedBrineORCV2?.valueB || 0,
      valueC: brineORCMonitoringStore?.selectedBrineORCV2?.valueC || 0,
      valueD: brineORCMonitoringStore?.selectedBrineORCV2?.valueD || 0,
      valueE: brineORCMonitoringStore?.selectedBrineORCV2?.valueE || 0,
    };
  }, [brineORCMonitoringStore.selectedBrineORCV2]);

  const onSubmit = async (values: TBrineORCDataV2) => {
    await brineORCMonitoringStore.addBrineOrcV2([values]).then(() => {
      onClose();
      brineORCMonitoringStore.setSelectedOrcV2(null);
      resetForm();
    });
  };

  const { handleChange, values, errors, setFieldValue, resetForm } = useFormik({
    onSubmit,
    initialValues,
    validationSchema,
    enableReinitialize: true,
  });

  useEffect(() => {
    orcGridStore.getOrcOptions().then((res) => setOrcOptions(res));
    orcGroupGridStore.getOrcGroupOptions();
  }, []);

  return (
    <RkdModalForm
      toggleModal={open}
      handleCloseModal={() => {
        onClose();
        brineORCMonitoringStore.setSelectedOrcV2(null);
      }}
      width="600px"
      maxHeight="600px">
      <Typography variant="h1" mb={2}>
        {brineORCMonitoringStore.selectedBrineORCV2 ? "Update" : "Create"} Brine
        ORC
      </Typography>
      <RkdForm
        initialValues={initialValues}
        submitAction={onSubmit}
        validationSchema={validationSchema}
        actionAfterSubmit={() => onClose()}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="demo-multiple-checkbox-label">ORC Group</InputLabel>
            <Select
              value={values.orcGroupId}
              onChange={handleChange}
              name="orcGroupId"
              input={<OutlinedInput label="ORC Group" />}>
              {orcGroupGridStore.orcGroupOptions.map((item, index) => (
                <MenuItem key={index} value={item.value}>
                  <ListItemText primary={item.text} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="demo-multiple-checkbox-label">ORC</InputLabel>
            <Select
              value={values.orcId}
              onChange={handleChange}
              name="orcId"
              input={<OutlinedInput label="ORC" />}>
              {orcOptions.map((item, index) => (
                <MenuItem key={index} value={item.value}>
                  <ListItemText primary={item.text} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Date"
                value={values.brineOrcDate}
                onChange={(e) => {
                  setFieldValue(
                    "brineOrcDate",
                    new Date((e as Date).setHours(0, 0, 0, 0))
                  );
                }}
                renderInput={(props: any) => (
                  <TextField label="Date" {...props} />
                )}
              />
            </LocalizationProvider>
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <TextField
              label="Flow Rate"
              value={values.flowRate}
              fullWidth
              name="flowRate"
              required
              type="number"
              error={!!errors.flowRate}
              helperText={errors.flowRate}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <TextField
              label="Pressure In"
              value={values.pressureInlet}
              fullWidth
              type="number"
              name="pressureInlet"
              required
              error={!!errors.pressureInlet}
              helperText={errors.pressureInlet}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <TextField
              label="Pressure Outlet"
              value={values.pressureOutlet}
              fullWidth
              type="number"
              name="pressureOutlet"
              required
              error={!!errors.pressureOutlet}
              helperText={errors.pressureOutlet}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <TextField
              label="Delta Pressure"
              value={values.deltaPressure}
              fullWidth
              name="deltaPressure"
              required
              type="number"
              error={!!errors.deltaPressure}
              helperText={errors.deltaPressure}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <TextField
              label="Temperature Inlet"
              value={values.temperatureInlet}
              fullWidth
              name="temperatureInlet"
              required
              type="number"
              error={!!errors.temperatureInlet}
              helperText={errors.temperatureInlet}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <TextField
              label="Temperature Outlet"
              value={values.temperatureOutlet}
              fullWidth
              name="temperatureOutlet"
              required
              type="number"
              error={!!errors.temperatureOutlet}
              helperText={errors.temperatureOutlet}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <TextField
              label="Delta Temperature"
              value={values.deltaTemperature}
              fullWidth
              name="deltaTemperature"
              required
              type="number"
              error={!!errors.deltaTemperature}
              helperText={errors.deltaTemperature}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <TextField
              label="Gross Generation"
              value={values.grossGeneration}
              fullWidth
              name="grossGeneration"
              required
              type="number"
              error={!!errors.grossGeneration}
              helperText={errors.grossGeneration}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <TextField
              label="Polymerization"
              value={values.polymerization}
              fullWidth
              name="polymerization"
              required
              type="number"
              error={!!errors.polymerization}
              helperText={errors.polymerization}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <TextField
              label="Decline Generation"
              value={values.declineGeneration}
              fullWidth
              name="declineGeneration"
              required
              type="number"
              error={!!errors.declineGeneration}
              helperText={errors.declineGeneration}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <TextField
              label="Decline Generation Chart"
              value={values.declineDenerationChart}
              fullWidth
              name="declineDenerationChart"
              required
              type="number"
              error={!!errors.declineDenerationChart}
              helperText={errors.declineDenerationChart}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <TextField
              label="Value A"
              value={values.valueA}
              fullWidth
              name="valueA"
              required
              type="number"
              error={!!errors.valueA}
              helperText={errors.valueA}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <TextField
              label="Value B"
              value={values.valueB}
              fullWidth
              name="valueB"
              required
              type="number"
              error={!!errors.valueB}
              helperText={errors.valueB}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <TextField
              label="Value C"
              value={values.valueC}
              fullWidth
              name="valueC"
              required
              type="number"
              error={!!errors.valueC}
              helperText={errors.valueC}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <TextField
              label="Value D"
              value={values.valueD}
              fullWidth
              name="valueD"
              required
              type="number"
              error={!!errors.valueD}
              helperText={errors.valueD}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <TextField
              label="Value E"
              value={values.valueE}
              fullWidth
              name="valueE"
              required
              type="number"
              error={!!errors.valueE}
              helperText={errors.valueE}
              onChange={handleChange}
            />
          </FormControl>
        </Box>
      </RkdForm>
      <Box>
        <Box
          sx={{
            mt: "24px",
            display: "flex",
            gap: "12px",
            justifyContent: "flex-end",
          }}>
          <Box>
            <Button
              disabled={brineORCMonitoringStore.brineOrcAdEditLoading}
              variant="contained"
              color="error"
              onClick={() => {
                onClose();
                brineORCMonitoringStore.setSelectedOrcV2(null);
              }}>
              Cancel
            </Button>
          </Box>
          <Box>
            <LoadingButton
              loading={brineORCMonitoringStore.brineOrcAdEditLoading}
              onClick={() => onSubmit(values)}
              type="submit"
              variant="contained"
              color="primary">
              Submit
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </RkdModalForm>
  );
}

export default BrineORCCreateModal;
