import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { v4 as uuidv4 } from "uuid";
import { Box, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useStore } from "../../../app/stores/store";
import RkdCard from "../../../app/component/card/RkdCard";
import { TPtReportWellDataResponse } from "../../../app/models/ptModule";
import WellDateGrid from "./components/WellDateGrid";
import ReportDynamicLineChart from "./components/ReportDynamicLineChart";
import converttoYYYYMMDDD from "./utils/utils";

export type TDisplaySelectedWell = {
  id: string;
  wellName: string;
  wellId: string;
  date: string;
  table: TPtReportWellDataResponse[];
};

function PTReportForm() {
  const { ptModuleStore } = useStore();
  const {
    getPtReportDateByWellOptions,
    ptReportDateByWellOptions,
    getPtReportWellOptions,
    ptReportWellOptions,
    getPtReportChartData,
    ptReportChartData,
    getDecimationReportFile,
    loading,
  } = ptModuleStore;
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [selectedWell, setSelectedWell] = useState("");
  const [displaySelectedGridData, setDisplaySelectedGridData] = useState<TDisplaySelectedWell[]>([]);

  const handleAddGridData = () => {
    let temp = [...displaySelectedGridData];

    temp.push({
      id: uuidv4(),
      wellId: selectedWell,
      wellName: ptReportWellOptions.find((item) => item.value === selectedWell)?.text ?? "",
      date: selectedDate,
      table: [],
      // table: ptReportWellData,
    });

    setDisplaySelectedGridData(temp);
    setSelectedDate("");
  };

  const handleDownloadExcel = () => {
    getDecimationReportFile([...displaySelectedGridData].map((item) => ({ wellId: item.wellId, date: converttoYYYYMMDDD(item.date) })));
  };

  const handleSelectWell = (id: string) => {
    setSelectedWell(id);
    setSelectedDate("");
  };

  useEffect(() => {
    getPtReportWellOptions();
  }, [getPtReportWellOptions]);

  useEffect(() => {
    if (!selectedWell) return;

    getPtReportDateByWellOptions(selectedWell);
  }, [getPtReportDateByWellOptions, selectedWell]);

  useEffect(() => {
    if (!displaySelectedGridData.length) return;

    getPtReportChartData([...displaySelectedGridData].map((item) => ({ wellId: item.wellId, date: converttoYYYYMMDDD(item.date) })));
  }, [displaySelectedGridData, getPtReportChartData]);

  return (
    <RkdCard title="PT Report" subtitle="View & Download PT Data from This Page">
      <Grid container display="flex" justifyContent="space-between">
        <Grid item xs={6} sx={{ display: "flex", gap: "12px" }}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel>Well</InputLabel>
            <Select value={selectedWell} label="Well" onChange={(e) => handleSelectWell(e.target.value)}>
              {ptReportWellOptions.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel>Date</InputLabel>
            <Select disabled={!selectedWell || loading} value={selectedDate} label="Date" onChange={(e) => setSelectedDate(e.target.value)}>
              {ptReportDateByWellOptions.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} display="flex" justifyContent="flex-end" gap="12px">
          <LoadingButton
            disabled={!selectedDate}
            loading={loading}
            sx={{ height: "100%" }}
            component="label"
            variant="contained"
            onClick={handleAddGridData}
          >
            Add
          </LoadingButton>
          <LoadingButton
            disabled={!displaySelectedGridData.length}
            loading={loading}
            sx={{ height: "100%" }}
            component="label"
            variant="contained"
            onClick={handleDownloadExcel}
          >
            Download
          </LoadingButton>
        </Grid>
        <Grid item xs={12}>
          {!!displaySelectedGridData.length ? (
            <Box>
              <Divider sx={{ mt: "12px", mb: "24px" }} />
              <Typography variant="h2" sx={{ textAlign: "center" }}>
                Selected Data
              </Typography>
              <Box sx={{ width: "100%", my: "24px" }}>
                <WellDateGrid setDisplaySelectedGridData={setDisplaySelectedGridData} data={displaySelectedGridData} />
                <ReportDynamicLineChart chartData={ptReportChartData!} />
              </Box>
            </Box>
          ) : undefined}
        </Grid>
      </Grid>
    </RkdCard>
  );
}

export default observer(PTReportForm);
