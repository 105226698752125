import { useMemo } from "react";
import { observer } from "mobx-react-lite";
import ApexCharts from "react-apexcharts";
import { Box, Typography } from "@mui/material";
import { PTReportChartDataResponse } from "../../../../app/models/ptModule";

// const series = [
//   {
//     name: "Pressure 1",
//     data: [
//       { x: 479, y: 5840 },
//       { x: 480, y: 4840 },
//       { x: 486, y: 3840 },
//       { x: 490, y: 3440 },
//       { x: 491, y: 3240 },
//       { x: 492, y: 2740 },
//       { x: 493, y: 1740 },
//       { x: 494, y: 1440 },
//       { x: 496, y: 1140 },
//       { x: 497, y: 340 },
//       { x: 498, y: 190 },
//       { x: 499, y: 170 },
//       { x: 500, y: 100 },
//     ],
//   },
//   {
//     name: "Temperature 2",
//     data: [
//       { x: 501, y: 9 },
//       { x: 502, y: 184 },
//       { x: 503, y: 1184 },
//       { x: 504, y: 1909 },
//       { x: 510, y: 2009 },
//       { x: 511, y: 2459 },
//       { x: 513, y: 3809 },
//       { x: 514, y: 4109 },
//       { x: 517, y: 5109 },
//       { x: 520, y: 6109 },
//     ],
//   },
// ];

type TReportDynamicLineChart = {
  chartData: PTReportChartDataResponse;
};

function ReportDynamicLineChart({ chartData }: TReportDynamicLineChart) {
  const seriesDataMemoized = useMemo(
    () =>
      chartData?.series?.map((chart) => ({
        name: chart.name,
        data: chart.data.map((point) => [point.x, point.y]),
      })),
    [chartData]
  );

  return (
    <Box sx={{ height: "320px", display: "flex", justifyContent: "center", my: "64px", width: "100%", flexDirection: "column" }}>
      <Typography variant="h2" sx={{ textAlign: "center", my: "24px" }}>
        Generated Chart
      </Typography>
      <Box sx={{}}>
        <ApexCharts
          height={360}
          series={seriesDataMemoized ?? []}
          type="line"
          options={{
            chart: {
              type: "line",
            },
            dataLabels: {
              enabled: true,
            },
            legend: {
              show: true,
            },
          }}
        />
      </Box>
    </Box>
  );
}

export default observer(ReportDynamicLineChart);
