import { useEffect, useRef, useState } from "react";
import { Box, Button, Grid, MenuItem, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import RkdCard from "../../../app/component/card/RkdCard";
import { useStore } from "../../../app/stores/store";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import { DownloadOutlined, UploadOutlined } from "@mui/icons-material";
import BrineORCPageCreateGridV2 from "./BrineORCPageCreateGridV2";
import BrineORCCreateModal from "./BrineORCCreateModal";
import BrineORCImportPreviewGrid from "./BrineORCImportPreviewGrid";
import { TOrcOptions } from "../../../app/models/orc";

function BrineORCPageV2() {
  const { brineORCMonitoringStore, orcGridStore } = useStore();
  const navigate = useNavigate();
  const [dateByUser, setDateByUser] = useState<Date>(new Date());
  const hiddenFileInput = useRef<any>(null);
  const { date } = useParams();

  const [openModal, setOpenModal] = useState(false);
  const [openModalPreview, setOpenModalPreview] = useState(false);
  const [orcOptions, setOrcOptions] = useState<TOrcOptions[] | []>([]);
  const [selectedORC, setSelectedOrc] = useState("");
  const [startDate, setStartDate] = useState<Date>(
    new Date(new Date().getFullYear(), 0, 1)
  );
  const [endDate, setEndDate] = useState<Date>(new Date());

  const onSubmit = async () => {
    let payload = {
      date: date ?? format(new Date(dateByUser), "yyyy-MM-dd"),
      table: brineORCMonitoringStore.brineORCMonitoringDetail,
    };

    brineORCMonitoringStore.addEditBrineOrcMonitoring(payload).then(() => {
      navigate("/geochemistry-brine");
    });
  };

  const downloadTempalte = () =>
    brineORCMonitoringStore.getBrineOrcTemplate(
      new Date(dateByUser).toISOString()
    );

  const handleUploadTemplate = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const formData = new FormData();

      formData.append("date", new Date(dateByUser).toISOString());
      formData.append("file", e.target.files[0]);

      brineORCMonitoringStore.uploadBrineOrcTemplate(formData).then((res) => {
        setOpenModalPreview(true);
      });
    }
    e.target.value = "";
  };

  useEffect(() => {
    brineORCMonitoringStore.getBrineORCMonitoringGridV2(
      startDate,
      endDate,
      selectedORC === "" ? [] : [selectedORC]
    );
  }, [brineORCMonitoringStore, startDate, endDate, selectedORC]);

  useEffect(() => {
    orcGridStore.getOrcOptions().then((res) => setOrcOptions(res));
  }, []);

  return (
    <Grid container>
      <Grid item xs={12}>
        <RkdCard title="Brine ORC Monitoring">
          <Grid container gap={0}>
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
                justifyContent: "flex-end",
                alignItems: "center",
              }}>
              <TextField
                select
                label="ORC"
                value={selectedORC}
                fullWidth
                required
                name="orc"
                onChange={(e) => setSelectedOrc(e.target.value)}>
                <MenuItem value={""}>All ORC</MenuItem>
                {orcOptions.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.text}
                  </MenuItem>
                ))}
              </TextField>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  inputFormat="dd MMM yyyy"
                  onChange={(e: any) => setStartDate(e)}
                  renderInput={(props) => (
                    <TextField required fullWidth name="startDate" {...props} />
                  )}
                />
                <DatePicker
                  label="End Date"
                  value={endDate}
                  inputFormat="dd MMM yyyy"
                  onChange={(e: any) => setEndDate(e)}
                  renderInput={(props) => (
                    <TextField required fullWidth name="EndDate" {...props} />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
                justifyContent: "flex-end",
                alignItems: "center",
              }}>
              <Box sx={{ height: "100%" }}>
                <LoadingButton
                  loading={brineORCMonitoringStore.loadingTemplate}
                  sx={{ height: "100%", whiteSpace: "nowrap" }}
                  component="label"
                  variant="contained"
                  startIcon={<DownloadOutlined />}
                  onClick={() => downloadTempalte()}>
                  Download Template
                </LoadingButton>
              </Box>
              <Box sx={{ height: "100%" }}>
                <LoadingButton
                  loading={brineORCMonitoringStore.loadingTemplate}
                  sx={{ height: "100%", whiteSpace: "nowrap" }}
                  component="label"
                  variant="contained"
                  startIcon={<UploadOutlined />}>
                  Upload Template
                  <input
                    ref={hiddenFileInput}
                    type="file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    hidden
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleUploadTemplate(e)
                    }
                  />
                </LoadingButton>
              </Box>
              <Box sx={{ height: "100%" }}>
                <LoadingButton
                  loading={brineORCMonitoringStore.loadingTemplate}
                  sx={{ height: "100%", whiteSpace: "nowrap" }}
                  component="label"
                  variant="contained"
                  onClick={() => setOpenModal(true)}>
                  Add
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
          <BrineORCPageCreateGridV2 />
        </RkdCard>
        <BrineORCCreateModal
          open={openModal}
          onClose={() => setOpenModal(false)}
        />
        <BrineORCImportPreviewGrid
          open={openModalPreview}
          onClose={() => setOpenModalPreview(false)}
          data={brineORCMonitoringStore.brineORCImportPayload}
        />
      </Grid>
    </Grid>
  );
}

export default observer(BrineORCPageV2);
