import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { StyledDataGridPremium } from "../../../app/component/StyledDataGridPremium";
import { GridValidRowModel, GridColDef } from "@mui/x-data-grid-premium";
import EditIcon from "@mui/icons-material/Edit";
import {
  TBrineORCDataV2,
  TBrineORCDetailDataResponse,
} from "../../../app/models/geochemistry";
import { useState } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { DeleteOutlined } from "@mui/icons-material";
import BrineORCCreateModal from "./BrineORCCreateModal";
import { format } from "date-fns";
import { Box } from "@mui/system";

function BrineORCPageCreateGridV2() {
  const { brineORCMonitoringStore } = useStore();
  const [openModal, setOpenModal] = useState(false);

  const handleEdit = (data: TBrineORCDataV2) => {
    brineORCMonitoringStore.setSelectedOrcV2(data);
    setOpenModal(true);
  };

  const gridColumns: GridColDef[] = [
    {
      field: "actions",
      headerName: "Action",
      type: "actions",
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            color="info"
            label="View"
            onClick={() => {
              handleEdit(params.row);
            }}
          />,
          <GridActionsCellItem
            icon={<DeleteOutlined />}
            color="error"
            label="View"
            onClick={() => {
              brineORCMonitoringStore.deleteBrineORCMonitoringGrid({
                ids: [params.row.id],
              });
            }}
          />,
        ];
      },
    },
    {
      field: "orcName",
      headerName: "ORC Name",
      align: "center",
      width: 120,
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "brineOrcDate",
      headerName: "Date",
      align: "center",
      width: 120,
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => (
        <Box>{format(new Date(params.row.brineOrcDate), "dd MMM yyyy")}</Box>
      ),
    },
    {
      field: "flowRate",
      headerName: "Flow Rate",
      align: "center",
      width: 120,
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "pressureInlet",
      headerName: "Pressure Inlet",
      align: "center",
      width: 120,
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "pressureOutlet",
      headerName: "Pressure Outlet",
      align: "center",
      width: 120,
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "deltaPressure",
      headerName: "Delta Pressure",
      align: "center",
      width: 120,
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "temperatureInlet",
      headerName: "Temperature Inlet",
      align: "center",
      width: 120,
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "temperatureOutlet",
      headerName: "temperature Outlet",
      align: "center",
      width: 120,
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "deltaTemperature",
      headerName: "Delta Temperature",
      align: "center",
      width: 120,
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "grossGeneration",
      headerName: "Gross Generation",
      align: "center",
      width: 120,
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "polymerization",
      headerName: "Polymerization",
      align: "center",
      width: 120,
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "declineGeneration",
      headerName: "Decline Generation",
      align: "center",
      width: 120,
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "declineDenerationChart",
      headerName: "Decline Generation Chart",
      align: "center",
      width: 120,
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "valueA",
      headerName: "Value A",
      align: "center",
      width: 120,
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "valueB",
      headerName: "Value B",
      align: "center",
      width: 120,
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "valueC",
      headerName: "Value C",
      align: "center",
      width: 120,
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "valueD",
      headerName: "Value D",
      align: "center",
      width: 120,
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "valueE",
      headerName: "value E",
      align: "center",
      width: 120,
      headerAlign: "center",
      sortable: false,
    },
  ];

  return (
    <>
      <StyledDataGridPremium
        experimentalFeatures={{ columnGrouping: true }}
        getRowId={(row) => row.id}
        loading={brineORCMonitoringStore.loadingGrid}
        autoHeight
        showCellVerticalBorder
        showColumnVerticalBorder
        pagination
        pageSizeOptions={[10, 25, 50, 100]}
        columns={gridColumns}
        rows={brineORCMonitoringStore?.brineORCGridV2 || []}
      />
      <BrineORCCreateModal
        open={openModal}
        onClose={() => setOpenModal(false)}
      />
    </>
  );
}

export default observer(BrineORCPageCreateGridV2);
