import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { StyledDataGridPremium } from "../../../app/component/StyledDataGridPremium";
import { GridValidRowModel, GridColDef } from "@mui/x-data-grid-premium";
import { TBrineORCDataV2 } from "../../../app/models/geochemistry";
import { useState } from "react";
import RkdModalForm from "../../../app/component/modal/RkdModalForm";
import { Box, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { format } from "date-fns";

interface IBrineORCImportPreviewGridProps {
  open: boolean;
  data: TBrineORCDataV2[];
  onClose: () => void;
}

function BrineORCImportPreviewGrid({
  open,
  data,
  onClose,
}: IBrineORCImportPreviewGridProps) {
  const { brineORCMonitoringStore } = useStore();

  const onSubmit = () => {
    brineORCMonitoringStore.addBrineOrcV2(data).then(() => onClose());
  };

  const gridColumns: GridColDef[] = [
    {
      field: "orcName",
      headerName: "ORC Name",
      align: "center",
      width: 120,
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "brineOrcDate",
      headerName: "Date",
      align: "center",
      width: 120,
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => (
        <Box>{format(new Date(params.row.brineOrcDate), "dd MMM yyyy")}</Box>
      ),
    },
    {
      field: "flowRate",
      headerName: "Flow Rate",
      align: "center",
      width: 120,
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "pressureInlet",
      headerName: "Pressure Inlet",
      align: "center",
      width: 120,
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "pressureOutlet",
      headerName: "Pressure Outlet",
      align: "center",
      width: 120,
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "deltaPressure",
      headerName: "Delta Pressure",
      align: "center",
      width: 120,
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "temperatureInlet",
      headerName: "Temperature Inlet",
      align: "center",
      width: 120,
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "temperatureOutlet",
      headerName: "temperature Outlet",
      align: "center",
      width: 120,
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "deltaTemperature",
      headerName: "Delta Temperature",
      align: "center",
      width: 120,
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "grossGeneration",
      headerName: "Gross Generation",
      align: "center",
      width: 120,
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "polymerization",
      headerName: "Polymerization",
      align: "center",
      width: 120,
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "declineGeneration",
      headerName: "Decline Generation",
      align: "center",
      width: 120,
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "declineDenerationChart",
      headerName: "Decline Generation Chart",
      align: "center",
      width: 120,
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "valueA",
      headerName: "Value A",
      align: "center",
      width: 120,
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "valueB",
      headerName: "Value B",
      align: "center",
      width: 120,
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "valueC",
      headerName: "Value C",
      align: "center",
      width: 120,
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "valueD",
      headerName: "Value D",
      align: "center",
      width: 120,
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "valueE",
      headerName: "value E",
      align: "center",
      width: 120,
      headerAlign: "center",
      sortable: false,
    },
  ];

  return (
    <RkdModalForm
      toggleModal={open}
      handleCloseModal={onClose}
      maxHeight="600px">
      <StyledDataGridPremium
        experimentalFeatures={{ columnGrouping: true }}
        getRowId={(row) => Math.random().toString(36).substring(2, 15)}
        loading={brineORCMonitoringStore.loadingGrid}
        autoHeight
        showCellVerticalBorder
        showColumnVerticalBorder
        pagination
        pageSizeOptions={[10, 25, 50, 100]}
        columns={gridColumns}
        rows={data || []}
      />
      <Box>
        <Box
          sx={{
            mt: "24px",
            display: "flex",
            gap: "12px",
            justifyContent: "flex-end",
          }}>
          <Box>
            <Button
              disabled={brineORCMonitoringStore.brineOrcAdEditLoading}
              variant="contained"
              color="error"
              onClick={() => {
                onClose();
                brineORCMonitoringStore.setBrineORCImportPayload({ table: [] });
              }}>
              Cancel
            </Button>
          </Box>
          <Box>
            <LoadingButton
              loading={brineORCMonitoringStore.brineOrcAdEditLoading}
              onClick={() => onSubmit()}
              type="submit"
              variant="contained"
              color="primary">
              Submit
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </RkdModalForm>
  );
}

export default observer(BrineORCImportPreviewGrid);
