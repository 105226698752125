import { Dispatch, SetStateAction } from "react";
import { GridValidRowModel, GridColDef } from "@mui/x-data-grid-premium";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import { StyledDataGridPremium } from "../../../../app/component/StyledDataGridPremium";
import { TDisplaySelectedWell } from "../PTReportForm";

export type TReportWellDateGridProps = {
  setDisplaySelectedGridData: Dispatch<SetStateAction<TDisplaySelectedWell[]>>;
  data: TDisplaySelectedWell[];
};

export default function WellDateGrid({ data, setDisplaySelectedGridData }: TReportWellDateGridProps) {
  const gridColumns: GridColDef<GridValidRowModel>[] = [
    {
      field: "wellName",
      headerName: "Well",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "date",
      headerName: "Date",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Action",
      type: "actions",
      width: 70,
      getActions: (params) => [
        <GridActionsCellItem icon={<DeleteIcon />} color="error" label="Delete" onClick={() => handleDeleteRow(params.row.id)} />,
      ],
    },
  ];

  const handleDeleteRow = (id: string) => {
    setDisplaySelectedGridData(data.filter((row) => row.id !== id));
  };

  return <StyledDataGridPremium disableRowSelectionOnClick columns={gridColumns} rows={data} autoHeight />;
}
